import { Link, graphql } from "gatsby"
import React from 'react'
import MainLayout from '../layouts/MainLayout'
import SEO from "../components/SEO"
import Banner from '../components/our-work/first-level/Banner'
import OurWorkComponent from '../components/OurWorkComponent'

import Gallery from '@browniebroke/gatsby-image-gallery'
import ArrowBack from '../assets/images/arrow_back-24px.svg'

export default function GalleryTemplate({ data }) {
    const images = data.strapiGalleries.pictures.map((picture) => picture.image.localFile.childImageSharp)
    return (
        <MainLayout>
            <SEO title={`${data.strapiGalleries.seo.title}`} description={`${data.strapiGalleries.seo.description}`} />
            <Banner sources={data.strapiGalleries.heroImage} />
            <section className="gallery">
                <h2>gallery: {data.strapiGalleries.name}</h2>
                <Gallery images={images} />
                <Link to={'/our-work/gallery'}><button className="btn btn-primary"><img src={ArrowBack} alt="arrow-back" /><span>back to gallery</span></button></Link>
            </section>
            <OurWorkComponent defaultType={data.strapiGalleries.name.split(' ').join('-')} />
        </MainLayout>
    )
}

export const query = graphql`
  query($slug: String!) {
    strapiGalleries(slug: { eq: $slug }) {
        name
        pictures {
            image {
                localFile {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 270
                            height: 270
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: CONSTRAINED)
                    }
                }
            }
        }
        heroImage {
            desktopImage {
                localFile {
                    publicURL
                }
            }
            mobileImage{
                localFile {
                    publicURL
                }
            }
        }
        seo {
            description
            title
        }
    }
  }
`